import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
Vue.prototype.$axios = axios
import qs from 'qs'
Vue.prototype.$qs = qs
import { Button, Form, Spin, Select, Tabs, Table, Dropdown, Menu, Input, InputNumber, Empty, Tooltip, Icon, ConfigProvider, DatePicker, TimePicker, Divider, Layout, Row, Col, message, notification, Modal, FormModel, Radio, Upload, Avatar, Checkbox, Popover, Cascader, Drawer, Descriptions, Switch, Calendar, Tree, Carousel } from 'ant-design-vue'
Vue.use(Button)
Vue.use(Form)
Vue.use(Spin)
Vue.use(Select)
Vue.use(Tabs)
Vue.use(Table)
Vue.use(Dropdown)
Vue.use(Menu)
Vue.use(Input)
Vue.use(InputNumber)
Vue.use(Empty)
Vue.use(Tooltip)
Vue.use(Icon)
Vue.use(ConfigProvider)
Vue.use(DatePicker)
Vue.use(TimePicker)
Vue.use(Divider)
Vue.use(Layout)
Vue.use(Row)
Vue.use(Col)
Vue.use(Modal)
Vue.use(FormModel)
Vue.use(Radio)
Vue.use(Upload)
Vue.use(Avatar)
Vue.use(Checkbox)
Vue.use(Popover)
Vue.use(Cascader)
Vue.use(Drawer)
Vue.use(Descriptions)
Vue.use(Switch)
Vue.use(Calendar)
Vue.use(Tree)
Vue.use(Carousel)
message.config({
  duration: 5,
  maxCount: 1
})
notification.config({
  duration: 5
})
Vue.prototype.$message = message;
Vue.prototype.$notification = notification
Vue.prototype.$warning = Modal.warning
Vue.prototype.$confirm = Modal.confirm
Vue.prototype.$info = Modal.info


Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
