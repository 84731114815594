import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home'
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "about" */ '../views/login/loginPage.vue')
  },
  {
    path: '/list',
    name: 'list',
    component: () => import(/* webpackChunkName: "about" */ '../views/list/listPage.vue')
  },
  {
    path: '/listPageDetail',
    name: 'listPageDetail',
    component: () => import(/* webpackChunkName: "about" */ '../views/list/listPageDetail.vue')
  },
  {
    path: '/detailForPrinter',
    name: 'detailForPrinter',
    component: () => import(/* webpackChunkName: "about" */ '../views/list/detailForPrinter.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach(function(to,from, next) {
  let luimJwt = localStorage.getItem('luim-jwt')
  luimJwt = JSON.parse(luimJwt)
  if (!luimJwt && to.path === '/') {
    next({path: '/login'})
    return;
  } else if (luimJwt && luimJwt.current_jwt) {
    if (to.path === '/' && from.path === '/') {
      next({path: '/list'})
      return;
    }
  }
  next()
})

export default router
